var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.dialog1,
            callback: function ($$v) {
              _vm.dialog1 = $$v
            },
            expression: "dialog1",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.openMention()
                    },
                  },
                },
                [_vm._v("Open Mention")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.dialog2,
            callback: function ($$v) {
              _vm.dialog2 = $$v
            },
            expression: "dialog2",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt-16" },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "12" } },
                                [
                                  _c(
                                    "Mentionable",
                                    {
                                      attrs: {
                                        keys: ["@"],
                                        items: _vm.items,
                                        offset: "6",
                                        "insert-space": "",
                                      },
                                      on: {
                                        open: _vm.onOpen,
                                        apply: _vm.onApply,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "no-result",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "dim" },
                                                [_vm._v(" No result ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "item-@",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "user" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "dim" },
                                                    [
                                                      _vm._v(
                                                        " (" +
                                                          _vm._s(item.name) +
                                                          ") "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item-#",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "issue" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "number" },
                                                    [
                                                      _vm._v(
                                                        " #" +
                                                          _vm._s(item.value) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "dim" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.label) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _c("v-textarea", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          rows: "1",
                                          outlined: "",
                                          "auto-grow": "",
                                          placeholder: "Note",
                                        },
                                        model: {
                                          value: _vm.text,
                                          callback: function ($$v) {
                                            _vm.text = $$v
                                          },
                                          expression: "text",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-card-actions", [_c("v-spacer")], 1),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }