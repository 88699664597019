<script>
import { Mentionable } from 'vue-mention'
import 'floating-vue/dist/style.css'
import Service from "@/services/Service.js";

export default {
  components: {
    Mentionable,
  },

  data () {
    return {
      text: '',
      dialog1: true,
      dialog2: false,
      items: [],
      users: [],
      issues: [
        {
          value: 123,
          label: 'Error with foo bar',
          searchText: 'foo bar'
        },
        {
          value: 42,
          label: 'Cannot read line',
          searchText: 'foo bar line'
        },
        {
          value: 77,
          label: 'I have a feature suggestion',
          searchText: 'feature'
        }
      ],
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      Service.getUsers()
        .then((response) => {
          this.users = response.data.map(u => {
            // return {...item, expanded: false};
            return {...u, value: u.name};
          });
          NProgress.done()
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
          NProgress.done()
        });
    },
    openMention() {
      this.dialog2 = true;
    },
    onOpen (key) {
      this.items = key === '@' ? this.users : this.issues
    },

    onApply (item, key, replacedWith) {
      console.log(item, `has been replaced with ${replacedWith}`)
    }
  },
}
</script>

<template>
  <div>
    <v-dialog v-model="dialog1">
      <div>
        <v-btn @click="openMention()">Open Mention</v-btn>
      </div>
    </v-dialog>
    <v-dialog v-model="dialog2">
      <div class="mt-16">
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <Mentionable
                  :keys="['@']"
                  :items="items"
                  offset="6"
                  insert-space
                  @open="onOpen"
                  @apply="onApply"
                  >
                    <v-textarea
                      type="text"
                      class="form-control"
                      rows="1"
                      outlined
                      auto-grow
                      v-model="text"
                      placeholder="Note"
                    ></v-textarea>
                    <template #no-result>
                      <div class="dim">
                        No result
                      </div>
                    </template>
                    <template #item-@="{ item }">
                      <div class="user">
                        {{ item.name }}
                        <span class="dim">
                          ({{ item.name }})
                        </span>
                      </div>
                    </template>
                    <template #item-#="{ item }">
                      <div class="issue">
                          <span class="number"> #{{ item.value }} </span>
                          <span class="dim">
                          {{ item.label }}
                          </span>
                      </div>
                    </template>
                  </Mentionable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs
                btn-default
                bg-gradient-danger
                shadow-danger
              "
              text
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs
                btn-success
                bg-gradient-success
                shadow-default
              "
              text
              @click="save"
            >
              Save
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<style>
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
}
</style>