import { render, staticRenderFns } from "./TestMention.vue?vue&type=template&id=dc7b0fda&"
import script from "./TestMention.vue?vue&type=script&lang=js&"
export * from "./TestMention.vue?vue&type=script&lang=js&"
import style0 from "./TestMention.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VContainer,VDialog,VRow,VSpacer,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dc7b0fda')) {
      api.createRecord('dc7b0fda', component.options)
    } else {
      api.reload('dc7b0fda', component.options)
    }
    module.hot.accept("./TestMention.vue?vue&type=template&id=dc7b0fda&", function () {
      api.rerender('dc7b0fda', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/case/TestMention.vue"
export default component.exports